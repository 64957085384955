
export const programs =[
  {
    "offer_id": "1",
    "offer_name": "World of Warships",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "$",
    "date": "2022-12-11",
    "reward": {
      "pl": 5,
      "us": 15
    },
    "imgUrl": "https://cdn.admitad-connect.com/public/campaign/images/2022/9/14/23756-2092b35b9cc0c398.svg",
    "description":"login and complete the tutorial and play 3 battles, Active Player",
    "terms":"Active Game, Mobile traffic is prohibited",
    "link":"https://ad.admitad.com/g/jzfutye5oj7e03ad1696d93bcfc1db"
  },
  {
    "offer_id": "2",
    "offer_name": "World of Tanks",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "$",
    "date": "2022-12-11",
    "reward": {
      "pl": 5,
      "us": 9,
    },
    "imgUrl": "https://cdn.admitad-connect.com/public/campaign/images/2021/4/7/22781-56fe0b16051f01e5.svg",
    "description":"ACTIVE GAME - At least the 5th level achievement in the game is requered",
    "terms":"ACTIVE GAME",
    "link":"https://ad.admitad.com/g/2iw2selhvz7e03ad1696ee610cb449",
  },

  {
    "offer_id": "3",
    "offer_name": "World of Warships",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "$",
    "date": "2022-12-11",
    "reward": {
      "pl": 9,
      "us": 9,
    },
    "imgUrl": "https://cdn.admitad-connect.com/public/campaign/images/2021/3/2/24573-e030fa3365bda7b0.png",
    "description":"ACTIVE GAME - At least the 5th level achievement in the game is requered",
    "terms":"ACTIVE GAME",
    "link":"https://ad.admitad.com/g/vbi68brvaf7e03ad16965000a8effe",
  },
  {
    "offer_id": "4",
    "offer_name": " Lineage 2 EU",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "$",
    "date": "2022-12-11",
    "reward": {
      "pl": 8,
      "us": 0,
    },
    "imgUrl": "https://cdn.admitad-connect.com/public/campaign/images/2021/10/1/26379-2b6fd593e908b8b6.jpg",
    "description":"ACTIVE GAME - At least the 5th level achievement in the game is requered",
    "terms":"ACTIVE GAME",
    "link":"https://ad.admitad.com/g/9f1xof3ccd7e03ad16968cff89d2f3",
  },
  {
    "offer_id": "5",
    "offer_name": "Forge of Empires PL",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "€",
    "date": "2022-12-11",
    "reward": {
      "pl": 0.8,
      "us": 0,
    },
    "imgUrl": "https://cdn.admitad-connect.com/public/campaign/images/2022/5/5/22015-11d4af875d75c503.svg",
    "description":"login",
    "terms":"uniq IP",
    "link":"https://ad.admitad.com/g/9f1xof3ccd7e03ad16968cff89d2f3",
  },
  {
    "offer_id": "6",
    "offer_name": "Elvenar PL",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "€",
    "date": "2022-12-11",
    "reward": {
      "pl": 0.8,
      "us": 0,
    },
    "imgUrl": "https://cdn.admitad-connect.com/campaign/images/2022/8/22/18164-7dcf79443ebb2cdd.png",
    "description":"login",
    "terms":"uniq IP",
    "link":"https://ad.admitad.com/g/7s1ka057tn7e03ad16967853ca52c5",
  },
  {
    "offer_id": "7",
    "offer_name": "My Little Farmies PL",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "€",
    "date": "2022-12-11",
    "reward": {
      "pl": 1,
      "us": 0,
    },
    "imgUrl": "https://cdn.admitad-connect.com/campaign/images/2022/11/8/5659-6e41890c1b9349f4.jpg",
    "description":"login",
    "terms":"uniq IP",
    "link":"https://ad.admitad.com/g/51f76eb9737e03ad1696de341f78e4",
  },
  {
    "offer_id": "8",
    "offer_name": "My Kerfus Pet",
    "subid": "ciac",
    "subid1": "buddyPoints",
    "currency": "pln",
    "date": "2022-12-11",
    "reward": {
      "pl": 10,
      "us": 15,
    },
    "imgUrl": "https://play-lh.googleusercontent.com/Z3K9lICdthl6LWLQJsZwoqsQ8kMS_S7rXInkbvw50_Z2pf3uaOdwb7-w35-Ep-6lYFu2=w240-h480-rw",
    "description":"leave 5⭐⭐⭐⭐⭐, play at least 7days and watch at least 100 RewardedAds",
    "terms":"it's a joke LOL",
    "link":"http://bit.ly/kerfus",
  },

  

  
]